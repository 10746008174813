import React, { Component } from 'react'
import { withRouter } from 'react-router-dom';
import { connect } from "react-redux";
import Tabs from 'devextreme-react/ui/tabs';
import { Toolbar } from 'devextreme-react';
import axios from 'axios';
import * as actions from '../../store/actions/actions';
import IsAFTSAndroid from '../../utils/shared';

import './MSLToolbar.css';

const trimByChar = (string, character) =>  {
    const first = [...string].findIndex(char => char !== character);
    const last = [...string].reverse().findIndex(char => char !== character);
    return string.substring(first, string.length - last);
  }


class MSLToolBar extends Component {
    state = {
        activeEntities: []
    } 

   
   
    componentDidMount() {
        axios.get('api/companies/getallactivecompanies')
        .then(response => {
            this.setState({activeEntities: response.data.data});
        })

        this.props.onInitBadgeCount();
    }

  

    tabSelectedHandler = (e) => {
       
        this.props.history.push('/' + e.itemData.role);
    }

    componentDidUpdate() {
      
    }
 
    render() {

        let trimmedPath = trimByChar(this.props.location.pathname,'/');

        if(trimmedPath === 'approvals') {
            trimmedPath = 'changes';
        }

        if(trimmedPath === 'readinglistadmin') {
            trimmedPath = 'readinglist';
        }
        
        const found = this.props.menuItems.findIndex(x => x.role === trimmedPath);
        

        const searching = (trimmedPath === 'search');

        let toolbarItems = [{
            location: 'before',
            widget: 'dxButton',
            role: 'menu',
            options: {
                icon: 'menu',
                text: '',
            
            }
        }, 
        {
            location: 'before',
            widget: 'dxButton',
            role: 'hub',
            options: {
                icon: 'home',
                text: '',
                toolTip: "HUB",
                visible: !IsAFTSAndroid()
               
            }
        },
        {
            location: 'center',
            widget: 'dxButton',
            role: 'searchyButton',
            options : {
                icon : 'find'
            }
        },
        {
            location: 'center',
            widget: 'dxTextBox',
            role: 'search',
            options: {
                placeholder: "Search Manual",
                width: '200px',
                valueChangeEvent: 'change keydown',
                onEnterKey: this.props.searchEnterPress,
                value: searching ? this.props.searchText : null
            }
           
        },
        {
            location: 'after',
            widget: 'dxSelectBox',
            locateInMenu: 'auto',
            options: {
                width: 250,
                items: this.state.activeEntities,
                valueExpr: "id",
                displayExpr: "shortDescription",
                placeholder: "Select Entity...",
                onValueChanged: this.props.entityChanged,
                value: this.props.selectedEntity

              
            }
        },
      /*   {
            location: 'after',
            widget: 'dxButton',
            role: 'incident',
            options: {
                icon: 'airplane',
                text: '',
                toolTip: "Incident Report"
               
            }
        }, */
        {
            location: 'after',
            widget: 'dxButton',
            role: 'querytoadmin',
            options: {
                icon: 'help',
                text: '',
                toolTip: "Raise a query"
               
            }
        },
       
        {
            locateInMenu: 'always',
            text: 'Read and Sign History',
            role: 'rshistory'
            
        },  
      /*   {
            locateInMenu: 'always',
            text: 'Induction Questionnaire',
            role: 'induction'
            
        }, */
        {
            locateInMenu: 'always',
            text: 'Suggest Feature',
            role: 'suggest'
            
        },
        {
            locateInMenu: 'always',
            text: 'Preferences',
            role: 'preferences'
           
        },
        {
            locateInMenu: 'always',
            text: 'About',
            role: 'about'
        },
        {
            locateInMenu: 'always',
            text: 'Logout',
            role: 'logout',
            visible: IsAFTSAndroid()
            
        },  
        ]; 
       
      

        if(this.props.xentity === "False") {
            toolbarItems.splice(4,1);
        }
        
        return(
            <div className="TopToolbar">
                <Toolbar dataSource={toolbarItems} onItemClick={this.props.selected} />
                <Tabs width="100%" dataSource={this.props.menuItems} onItemClick={this.tabSelectedHandler} selectedIndex={found} />
             </div>
        );
    }
} 

const mapStateToProps = (state) => {
    return {
        user: state.oidc.user,
        selectedEntity: state.oidc.user.profile.selectedentity,
        xentity: state.oidc.user.profile.xentity
    };
  }

  const mapDispatchToProps = dispatch => {
    return {
        onInitBadgeCount: () => dispatch(actions.initBadgeCountsAsync())
    };
  }
  

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MSLToolBar));