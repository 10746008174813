import React, {Component} from 'react';
import { connect } from "react-redux";
import { ScrollView, Form } from 'devextreme-react';
import axios from 'axios';
import notify from 'devextreme/ui/notify';
import './incident.css';

const getHeight = () => {
    return (window.innerHeight - 120)
}

class Incident extends Component {
    state  = {
        submitted: false,
    }

    resize = () => {
        this.scrollview.instance.option('height',getHeight());
    }
    
    componentDidMount() {
        window.addEventListener('resize', this.resize)
    }
      
    componentWillUnmount() {
        window.removeEventListener('resize', this.resize);
    }

    submitMSR = (params) => {

        var result = params.validationGroup.validate();
        if(result.isValid) {
            axios.post('api/msrsubmit',this.incidentForm.instance.option("formData") )
            .then(response => {
                  
                notify("MSR Submitted","success", 1500);
                this.setState({ submitted: true});   
            })
            .catch(error => {
                console.log(error);
                notify("MSR NOT Submitted","danger", 1500);
                this.setState({ submitted: false});  
            });
        }
       
    }


    render() {

        let formData = {
            customer: '',
            dateTime: '',
            locationFacility: '',
            locationShopBench: '',
            visitReason: '',
            workorderNo: '',
            component: '',
            batchNo: '',
            partNo: '',
            serialNo: '',
            manualDoc: '',
            issueNoDate: '',
            figNo: '',
            pageRef: '',
            ata: '',
            summaryTitle: '',
            eventAndCause: '',
            morSubmissionRequired: false,
            inConfidence: false,
          //  disclaimer: 'This form is to be used as part of the BOS Aerospace Safety Management System. Its purpose is to allow you to report anything that you believe is having a negative or safety impact on our business, or to suggest improvements to our operation. Issues concerning procedures, equipment limitations, documentation, line management or team co-operation for example, (please don’t use it for complaints about financial or expense issues). If you were involved in an event that you feel we were lucky in avoiding a serious problem, please pass on the circumstances of the event so we can all learn from your near miss experience. We will address matters that are raised and bring them to the attention of the people who can change things. In this way we hope to effect change in the workplace for the benefit of us all.'
        }

        let formConfig = {
            items: [
                {
                    itemType: "group",
                    colSpan: 1,
                    caption: "Maintenance Safety Report - MSR",
                    
                    items: [
                        {
                            colSpan: 1,
                          
                            dataField: "customer",
                            editorType: "dxTextBox",
                            label : { 
                                text: "Customer"
                            },
                            validationRules: [
                            {
                                type: "stringLength",
                                max: 250,
                                message: "Answer cannot exceed 250 Characters"      
                            }]
                        },
                        {
                            colSpan: 1,
                          
                            dataField: "dateTime",
                            editorType: "dxTextBox",
                            editorOptions: {
                                type: "datetime"
                            },
                            label : { 
                                text: "Date and Time (Z)"
                            },
                            // validationRules: [
                            // {
                            //     type: "stringLength",
                            //     max: 250,
                            //     message: "Answer cannot exceed 250 Characters"      
                            // }]
                        },
                        {
                            colSpan: 1,
                          
                            dataField: "locationFacility",
                            editorType: "dxTextBox",
                            label : { 
                                text: "Location (Facility)"
                            },
                            validationRules: [
                            {
                                type: "stringLength",
                                max: 250,
                                message: "Answer cannot exceed 250 Characters"      
                            }]
                        },
                        {
                            colSpan: 1,
                          
                            dataField: "locationShopBench",
                            editorType: "dxTextBox",
                            label : { 
                                text: "Location (Shop/Bench)"
                            },
                            validationRules: [
                            {
                                type: "stringLength",
                                max: 250,
                                message: "Answer cannot exceed 250 Characters"      
                            }]
                        },
                        {
                            colSpan: 1,
                          
                            dataField: "visitReason",
                            editorType: "dxTextBox",
                            label : { 
                                text: "Visit Reason (O/H/Repair)"
                            },
                            validationRules: [
                            {
                                type: "stringLength",
                                max: 250,
                                message: "Answer cannot exceed 250 Characters"      
                            }]
                        },
                        {
                            colSpan: 1,
                          
                            dataField: "workorderNo",
                            editorType: "dxTextBox",
                            label : { 
                                text: "Workorder Number"
                            },
                            validationRules: [
                            {
                                type: "stringLength",
                                max: 250,
                                message: "Answer cannot exceed 250 Characters"      
                            }]
                        },
                        {
                            colSpan: 1,
                          
                            dataField: "component",
                            editorType: "dxTextBox",
                            label : { 
                                text: "Component"
                            },
                            validationRules: [
                            {
                                type: "stringLength",
                                max: 250,
                                message: "Answer cannot exceed 250 Characters"      
                            }]
                        },
                        {
                            colSpan: 1,
                          
                            dataField: "batchNo",
                            editorType: "dxTextBox",
                            label : { 
                                text: "Batch No"
                            },
                            validationRules: [
                            {
                                type: "stringLength",
                                max: 250,
                                message: "Answer cannot exceed 250 Characters"      
                            }]
                        },
                        {
                            colSpan: 1,
                          
                            dataField: "partNo",
                            editorType: "dxTextBox",
                            label : { 
                                text: "Part No"
                            },
                            validationRules: [
                            {
                                type: "stringLength",
                                max: 50,
                                message: "Answer cannot exceed 50 Characters"      
                            }]
                        },
                        {
                            colSpan: 1,
                          
                            dataField: "serialNo",
                            editorType: "dxTextBox",
                            label : { 
                                text: "Serial No"
                            },
                            validationRules: [
                            {
                                type: "stringLength",
                                max: 50,
                                message: "Answer cannot exceed 50 Characters"      
                            }]
                        },
                        {
                            colSpan: 1,
                          
                            dataField: "manualDoc",
                            editorType: "dxTextBox",
                            label : { 
                                text: "Manual/Document"
                            },
                            validationRules: [
                            {
                                type: "stringLength",
                                max: 250,
                                message: "Answer cannot exceed 250 Characters"      
                            }]
                        },
                        {
                            colSpan: 1,
                          
                            dataField: "issueNoDate",
                            editorType: "dxTextBox",
                            label : { 
                                text: "Issue No/Date"
                            },
                            validationRules: [
                            {
                                type: "stringLength",
                                max: 50,
                                message: "Answer cannot exceed 50 Characters"      
                            }]
                        },
                        {
                            colSpan: 1,
                          
                            dataField: "figNo",
                            editorType: "dxTextBox",
                            label : { 
                                text: "Figure No"
                            },
                            validationRules: [
                            {
                                type: "stringLength",
                                max: 50,
                                message: "Answer cannot exceed 50 Characters"      
                            }]
                        },
                        {
                            colSpan: 1,
                          
                            dataField: "pageRef",
                            editorType: "dxTextBox",
                            label : { 
                                text: "Page Ref"
                            },
                            validationRules: [
                            {
                                type: "stringLength",
                                max: 50,
                                message: "Answer cannot exceed 50 Characters"      
                            }]
                        },
                        {
                            colSpan: 1,
                          
                            dataField: "ata",
                            editorType: "dxTextBox",
                            label : { 
                                text: "ATA Code"
                            },
                            validationRules: [
                            {
                                type: "stringLength",
                                max: 20,
                                message: "Answer cannot exceed 20 Characters"      
                            }]
                        },
                        {
                            colSpan: 1,
                          
                            dataField: "summaryTitle",
                            editorType: "dxTextBox",
                            label : { 
                                text: "MSR Sumary Title"
                            },
                            validationRules: [
                            {
                                type: "stringLength",
                                max: 250,
                                message: "Answer cannot exceed 250 Characters"      
                            }]
                        },
                        {
                            colSpan: 1,
                          
                            dataField: "eventAndCause",
                            editorType: "dxTextArea",
                            label : { 
                                text: "Event and Cause: (Detailed description of event and its immediate cause,actions taken,and any subsequent events)"
                            },
                            validationRules: [
                            {
                                type: "stringLength",
                                max: 2000,
                                message: "Answer cannot exceed 2000 Characters"      
                            }]
                        },
                        {
                            colSpan: 1,
                          
                            dataField: "morSubmissionRequired",
                            editorType: "dxCheckBox",
                            label : { 
                                text: "Does this incident require an MOR Submission?"
                            },
                            // validationRules: [
                            // {
                            //     type: "stringLength",
                            //     max: 250,
                            //     message: "Answer cannot exceed 250 Characters"      
                            // }]
                        },
                        {
                            colSpan: 1,
                          
                            dataField: "inConfidence",
                            editorType: "dxCheckBox",
                            label : { 
                                text: "Treat Confidentially?"
                            },
                            // validationRules: [
                            // {
                            //     type: "stringLength",
                            //     max: 250,
                            //     message: "Answer cannot exceed 250 Characters"      
                            // }]
                        },
                        {
                            template: function (data,itemElement) {
                                var node = document.createElement("P");
                                var textNode = document.createTextNode("This form is to be used as part of the BOS Aerospace Safety Management System. Its purpose is to allow you to report anything that you believe is having a negative or safety impact on our business, or to suggest improvements to our operation. Issues concerning procedures, equipment limitations, documentation, line management or team co-operation for example, (please don’t use it for complaints about financial or expense issues). If you were involved in an event that you feel we were lucky in avoiding a serious problem, please pass on the circumstances of the event so we can all learn from your near miss experience. We will address matters that are raised and bring them to the attention of the people who can change things. In this way we hope to effect change in the workplace for the benefit of us all.");
                                node.appendChild(textNode);
                                itemElement.appendChild(node);
                            }
                        },
                        {
                            itemType: "button",
                            horizontalAlignment: "center",
                            colSpan: 1,
                            buttonOptions: {
                                text: "Submit",
                                type: "success",
                                useSubmitBehavior: false,
                                onClick: this.submitMSR
                            }
                        },
                        {
                            itemType: "empty",
                            colSpan: 1,
                        }
                    ]
                }
            ]
        };

        let incident = (
            <ScrollView height={getHeight} ref={(sv) => {this.scrollview = sv}}>
            <Form formData={formData} items={formConfig.items} ref={(form) => {this.incidentForm = form}}></Form>
            </ScrollView>
        )

        if(this.state.submitted == true) {
            incident = <p>Thank you for your submitted MOR, you will receive a copy of the MOR you submitted via email.</p>
        }

        return (
            <div id='incident'>
            {incident}
        
            </div>)
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.oidc.user,
    };
}

export default connect(mapStateToProps,null)(Incident)