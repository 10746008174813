import { createUserManager } from 'redux-oidc';

const userManagerConfig = {
    client_id: 'adminClient',
    // post logout redirect URI required for affinity
    post_logout_redirect_uri: `${window.location.protocol}//${window.location.hostname}${window.location.port ? `:${window.location.port}` : ''}/index.html`,
    redirect_uri: `${window.location.protocol}//${window.location.hostname}${window.location.port ? `:${window.location.port}` : ''}/callback`,
    response_type: 'token id_token',
    scope: 'openid profile AdminAPI UserInfo',
    authority: 'https://idp.afts.companymanual.co.uk',
 //   authority: 'https://localhost:5000',
 //   authority: 'https://idp.uat.notreal.com',
    silent_redirect_uri: `${window.location.protocol}//${window.location.hostname}${window.location.port ? `:${window.location.port}` : ''}/silent_renew.html`,
    automaticSilentRenew: false,
    filterProtocolClaims: true,
    loadUserInfo: true,
    monitorSession: false,
}

const userManager = createUserManager(userManagerConfig);

export default userManager;